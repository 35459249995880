<template>
  <v-data-table
      :headers="headers"
      :items="list"
      sort-by="sort"
      class="elevation-1"
  >

    <template v-slot:item.location="{ item }">
      {{ getLocation(item.location) }}
    </template>
    <template v-slot:item.group_id="{ item }">
      {{ getGroupName(item.group_id) }}
    </template>
    <template v-slot:item.presence_status="{ item }">
        {{ getPresenceName(item.presence_status) }}
    </template>

    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>メンバー編集</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
            >
              新規追加
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="editedItem.name_jp"
                        label="氏名"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="editedItem.group"
                        label="グループ"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="editedItem.location"
                        label="リモート"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="editedItem.presence"
                        label="出勤状態"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="editedItem.message"
                        label="1行メモ"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="editedItem.admin"
                        label="管理機能"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">メンバーを削除しますか？</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
          color="primary"
          @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "MemberList",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Array,
      default: () => [],
    },
    presence: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: '氏名', value: 'name_jp', sortable: false, width: '10%' },
      { text: 'グループ', value: 'group_id', width: '20%' },
      { text: 'リモート', value: 'location' },
      { text: '出勤状態', value: 'presence_status' },
      { text: '1行メッセージ', value: 'message' },
      { text: '管理機能', value: 'admin', sortable: false },
      { text: '機能', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name_jp: '',
      group: '',
      location: '',
      presence: '',
      message: '',
      admin: '',
    },
    defaultItem: {
      name_jp: '',
      group: '',
      location: '',
      presence: '',
      message: '',
      admin: '',
    },
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? '追加' : '編集'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
  },

  methods: {
    editItem (item) {
      this.editedIndex = this.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.list.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.list[this.editedIndex], this.editedItem)
      } else {
        this.list.push(this.editedItem)
      }
      this.close()
    },

    getPresenceName(id) {
      for(let val of this.presence) {
        if (val.id.toString() === id.toString()) {
          return val.name;
        }
      }
    },
    getGroupName(id) {
      for(let val of this.group) {
        if (val.id.toString() === id.toString()) {
          console.log("" + val.id.toString() + "/" + id.toString());
          return val.name;
        }
      }
    },
    getLocation(location) {
      if (location.toString() === "0") {
        return '自宅';
      } else if (location.toString() === "1") {
        return '出社';
      }
    },

  },
}
</script>

<style scoped>

</style>
<template>
  <v-container fluid>
    <div class="text-h5">メンバー</div>
    <v-divider class="ma-0"></v-divider>
    <v-tabs fixed-tabs>
      <v-tab>一覧・編集</v-tab>
      <v-tab>一括登録</v-tab>
      <v-tab-item>
        <v-card>
          <MemberList :list="users" :group="group" :presence="presence" ></MemberList>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-text></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>

import MemberList from "../../molecules/MemberList";
import firebase, {firebase_db} from "../../../plugins/firebase";

export default {
  name: "Member",
  components: {MemberList},
  data: () => ({
    users : [],
    presence : [],
    group: [],
  }),
  created() {
    firebase.onAuth();
    this.getCollectionUsers();
    this.getCollectionPresence();
    this.getCollectionGroups();
  },
  methods: {
    getCollectionUsers() {
      let collection = firebase_db.collection('users')
      collection.onSnapshot(snapshot => {
        this.users = [];
        snapshot.forEach(doc => {
          this.users.push(doc.data())
        })
      });
    },
    getCollectionGroups() {
      firebase_db.collection('group').orderBy('sort').onSnapshot(snapshot => {
        this.group = [];
        this.select_group = [{ name: 'すべて', id: "all" }];
        snapshot.forEach(doc => {
          let tmpVar = doc.data();
          tmpVar["id"] = doc.id;
          this.group.push(tmpVar);
          this.select_group.push(tmpVar);
        })
      });
    },
    getCollectionPresence() {
      firebase_db.collection('presence').orderBy('sort').onSnapshot(snapshot => {
        this.presence = [];
        this.select_presence = [{ name: 'すべて', id: "all" }];
        snapshot.forEach(doc => {
          let tmpVar = doc.data();
          tmpVar["id"] = doc.id;
          this.presence.push(tmpVar);
          this.select_presence.push(tmpVar);
        })
      });
    },
  }
}
</script>

<style scoped>

</style>